import $ from 'jquery'
const ANIMATION_DURATION = 1000

export default class Chiffres {

    constructor(){
        this.$chiffres = $('.chiffre')
        this.initChiffres()
    }

    initChiffres(){
        this.$chiffres.each( (i, el) => {
            let $chiffre = $(el)
            let value = parseInt($chiffre.data('value'))
            const duration = ANIMATION_DURATION;
            const steps = 50;
            const stepTime = duration / steps;
            const stepValue = value / steps ;
            
            $chiffre.parent().find('span').removeClass('opacity-0')

            for (let j = 0; j <= steps; j++) {
                window.setTimeout( () => {
                    let tempValue = Math.round(j * stepValue);
                    if( tempValue === 0 ) tempValue = 1
                    $chiffre.html( tempValue );
                }, j * stepTime )
            }

        })
    }

}
