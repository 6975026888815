// import 'styles/tw'
import 'styles/custom'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

import $ from 'jquery'
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Map from './map'
import Filters from './filters'
import Chiffres from './chiffres'
import autoComplete from "@tarekraafat/autocomplete.js";
// import Cookies from 'js-cookie'
// import { MarkerCluster } from 'leaflet.markercluster';

window.jQuery = $

const break_xl = 1280

class SPJ {

  constructor() {

    if ($('.js-filters').length > 0) {
      this.filters = new Filters()
    }
    if ($('#map-dip').length > 0) {
      this.map = new Map()
    }
    if ($('.chiffre').length > 0) {
      this.chiffres = new Chiffres()
    }

    if ($('.videos-slides').length > 0) {
      this.videos_slides = new Swiper('.videos-slides', {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 12,
        navigation: {
          nextEl: ".videos-slides-next",
          prevEl: ".videos-slides-prev",
        },
        breakpoints: {
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1920: {
            slidesPerView: 5,
            spaceBetween: 40,
          },
        }
      })
    }

    this.swiper = new Swiper('.swiper', {
      modules: [Navigation, Pagination, Autoplay],
      autoplay: {
        delay: 6000,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
    })
    this.swiper.on('slideChange', swiper => {
      $('.bullet').removeClass('active')
      $('.bullet:nth(' + swiper.activeIndex + ')').addClass('active')
    })

    let scrollPos = window.scrollY
    let scrollUpStartY
    let scrollTimeOut

    $(window).on('scroll', e => {

      const scrollUp = scrollPos > window.scrollY
      clearTimeout(scrollTimeOut)

      if (window.scrollY > 120) {
        if (scrollUp) {
          if (scrollUpStartY - window.scrollY > 150) {
            $('#nav-bar').removeClass('nav-hidden')
          }
        } else {
          scrollUpStartY = window.scrollY
          $('#nav-bar').addClass('nav-hidden')
        }
        $('#scroll-top').addClass('opacity-100').addClass('opacity-100')
      } else {
        $('#nav-bar').removeClass('nav-hidden')
        $('#scroll-top').removeClass('opacity-100').addClass('opacity-0')
      }
      scrollPos = window.scrollY

      scrollTimeOut = setTimeout(() => {
        scrollUpStartY = window.scrollY
      }, 200)
    })
    
    if (window.scrollY > 150) {
      $('#nav-bar').addClass('nav-hidden')
    }

    $('#scroll-top').on('click', () => {
      $([document.documentElement, document.body]).animate({
        scrollTop: 0
      }, 600);
    })
  }

  toggle(event, content) {
    let $content = $(content)
    let $icon = $(event.currentTarget).find('.icon-toggle')

    if ($content.hasClass('hidden')) {
      $content.attr('class', $content.data('class'))
      $content.removeClass('hidden')
      $icon.find('.open').addClass('hidden')
      $icon.find('.close').removeClass('hidden')
    } else {
      $content.data('class', $content.attr('class'))
      // $content.addClass('hidden')
      $content.attr('class', 'hidden')
      $icon.find('.close').addClass('hidden')
      $icon.find('.open').removeClass('hidden')
    }
  }

  toggle_nav() {

    const $nav = $('#main-nav')
    const $nav_overlay = $('#main-nav-overlay')

    if ($nav.hasClass('open')) {
      $nav.removeClass('open').addClass('close')
      $nav_overlay.addClass('opacity-0').removeClass('opacity-60')
    } else {
      $nav.removeClass('close').addClass('open')
      $nav_overlay.addClass('opacity-60').removeClass('opacity-0')
    }
  }

  allow_cookies(bool) {
    Cookies.set('rgpd', bool, { expires: 365 })
    window.location.reload()
    $('#rgpd').remove()
  }

  on_bullet_click(index) {
    this.swiper.slideTo(index)
  }
}


window.addEventListener('load', function (event) {

  window.spj = new SPJ()

  let nav_open = false

  const $body = $('body')
  const $overlay = $('#overlay')

  const $nav_toggler = $('#nav-toggler')
  // const $main = $('#main-col-right')

  // const $nav_sidebar = $('#nav-sidebar')
  // console.log($nav_sidebar[0])
  $('p, h1, h2').each((i, el) => {
    let text = $(el).html()
    // console.log(html)
    if (text.includes(' :')) {
      text = text.replace(' :', '&nbsp;:')
      $(el).html(text)
    }
  })

  init_search()
  init_accordion()


  /* -------------------------------------------------------------------------- */
  /*                                 ACCORDION                                  */
  /* -------------------------------------------------------------------------- */

  function init_accordion() {
    $('.accordion .accordion-item').on('click', function () {
      // let $excerpt = $(this).find('.accordion-excerpt')
      let $content = $(this).find('.accordion-content')
      let $plus = $(this).find('.plus')
      let $minus = $(this).find('.minus')
      if ($content.hasClass('hidden')) {
        $content.removeClass('hidden')
        // $excerpt.addClass('hidden')
        $minus.removeClass('hidden')
        $plus.addClass('hidden')
      } else {
        $content.addClass('hidden')
        // $excerpt.removeClass('hidden')
        $minus.addClass('hidden')
        $plus.removeClass('hidden')
      }
    })
  }

  /* -------------------------------------------------------------------------- */
  /*                                 OVERLAY                                    */
  /* -------------------------------------------------------------------------- */

  function show_overlay() {
    $overlay.addClass('opacity-80 pointer-events-all')
    $overlay.removeClass('opacity-0 pointer-events-none')
  }

  function hide_overlay() {
    $overlay.removeClass('opacity-80 pointer-events-all')
    $overlay.addClass('opacity-0 pointer-events-none')
  }

  /* -------------------------------------------------------------------------- */
  /*                                     NAV                                    */
  /* -------------------------------------------------------------------------- */



  // $nav_toggler.on('click', () => {
  //     if( window.innerWidth >= break_xl){
  //         if( nav_open_xl ){
  //             // $nav_sidebar.addClass('xl:-translate-x-72')
  //             // $nav_sidebar.removeClass('xl:translate-x-0')
  //             $main.removeClass('xl:pl-72')
  //         }else{
  //             // $nav_sidebar.addClass('xl:translate-x-0')
  //             // $nav_sidebar.removeClass('xl:-translate-x-72')
  //             $main.addClass('xl:pl-72')
  //         }
  //         nav_open_xl = !nav_open_xl
  //         Cookies.set('nvxl', nav_open_xl ? 1 : 0)
  //     }else{
  //         if( nav_open ){
  //             // $nav_sidebar.addClass('-translate-x-72')
  //             // $nav_sidebar.removeClass('translate-x-0')
  //             hide_overlay()
  //         }else{
  //             $nav_sidebar.addClass('translate-x-0')
  //             $nav_sidebar.removeClass('-translate-x-72')
  //             show_overlay()
  //         }
  //         nav_open = !nav_open
  //     }
  // })

  // window.addEventListener('resize', () => {
  //     if( window.innerWidth >= break_xl){
  //         // Close nav < break_xl
  //         $nav_sidebar.addClass('-translate-x-72')
  //         $nav_sidebar.removeClass('translate-x-0')
  //         hide_overlay()
  //         nav_open = false
  //     }
  // })

  /* -------------------------------------------------------------------------- */
  /*                                  SEARCH                                    */
  /* -------------------------------------------------------------------------- */

  function init_search() {

    const $search_wrapper = $('#search-wrapper')
    const $content = $('#content')
    const $search_input = $('#autoComplete')
    $('#search_toggler').on('click', () => {
      if ($search_wrapper.hasClass('hidden')) {
        $search_wrapper.removeClass('hidden')
        $content.addClass('blur-sm')
        $search_input.focus()
      }
    })

    let resultCount = 0
    let currentSelection
    const $form = $('#js-search-group')
    // const $input = $('#autoComplete')
    const url = $form.attr('action')
    const config = {
      placeHolder: "Rechercher...",
      data: {
        src: async (query) => {
          let results = await $.get(url + '.json?q=' + query)
          return results
        },
        keys: ['title'],
        cache: false,
      },
      resultsList: {
        element: (list, data) => {
          const info = document.createElement("p");
          resultCount = data.results.length
          if (data.results.length === 0) {
            info.innerHTML = '<div class="hidden sm:block px-3 py-3 text-xs border-t border-gray-300 mt-3">Aucun résultat</div>';
          } else {
            info.innerHTML = `<div class="hidden sm:block px-3 py-3 text-xs">${data.results.length} résultat(s)</div>`
          }
          list.prepend(info);
        },
        noResults: true,
        maxResults: 7,
        tabSelect: true
      },
      resultItem: {
        element: (item, data) => {
          // console.log(data)
          item.innerHTML = `
                        <div class="flex justify-between items-center">
                            <span class="text-xs md:text-sm">${data.match}</span>
                            <span class="hidden md:block uppercase text-xs text-gray-300">${data.value.type}</span>
                        </div>
                    `
        },
        highlight: {
          render: true
        }
      },
      submit: () => { },
    }

    const autoCompleteJS = new autoComplete(config);

    document.querySelector("#autoComplete").addEventListener("navigate", (event) => {
      currentSelection = event.detail.selection
      $search_input.val(event.detail.selection.value.title)
    });

    document.querySelector("#autoComplete").addEventListener("selection", function (event) {
      // "event.detail" carries the autoComplete.js "feedback" object
      window.location.href = event.detail.selection.value.url
    });

    $form.on('submit', e => {
      if (resultCount === 0 || currentSelection) {
        e.preventDefault()
      }
    })

    const close_search = () => {
      $search_wrapper.addClass('hidden')
      $content.removeClass('blur-sm')
    }

    $('#search-wrapper').on('click', e => {
      if (e.target === $('#search-overlay')[0] || e.target === $('#search-input-wrapper')[0]) {
        close_search()
      }
    })

    document.addEventListener('keydown', function (e) {
      if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
        close_search()
      }
    });

  }

  /* -------------------------------------------------------------------------- */
  /*                                    PRINT                                   */
  /* -------------------------------------------------------------------------- */

  window.onbeforeprint = function () {
    $(document).scrollTop(0)
  }

  /* -------------------------------------------------------------------------- */
  /*                                   CONTACT                                  */
  /* -------------------------------------------------------------------------- */

  var $form = $('#contact-form');

  if ($form.length) {
    var form = $form[0];

    const on_error = (errors) => {
      $('#response_error').html('')
      errors.forEach(e => {
        $('#response_error').append(`${e}<br/>`)
      })
      $('#response_error').removeClass('opacity-0')
    }

    const on_success = () => {
      $form.find('button').remove()
      $form.find('#contact-success').removeClass('hidden')
    }

    // var message = document.getElementById('response_message');
    var fields = {};

    form.querySelectorAll('[name]').forEach(function (field) {
      fields[field.name] = field;
    });


    // Displays all error messages and adds 'error' classes to the form fields with
    // failed validation.
    var handleError = function (response) {

      var errors = [];

      for (var key in response) {
        if (!response.hasOwnProperty(key)) continue;
        if (fields.hasOwnProperty(key)) fields[key].classList.add('error');
        Array.prototype.push.apply(errors, response[key]);
      }

      on_error(errors)

    }

    var onload = (e) => {
      if (e.target.status === 200) {
        on_success()
      } else {
        handleError(JSON.parse(e.target.response));
      }
    };

    var submit = function (e) {
      e.preventDefault();
      var request = new XMLHttpRequest();
      request.open('POST', e.target.action);
      request.onload = onload;
      request.send(new FormData(e.target));
      // Remove all 'error' classes of a possible previously failed validation.
      for (var key in fields) {
        if (!fields.hasOwnProperty(key)) continue;
        fields[key].classList.remove('error');
      }
      $('#response_error').addClass('opacity-0')

    };

    form.addEventListener('submit', submit);
  }

  /* -------------------------------------------------------------------------- */
  /*                               BROCHURES FORM                               */
  /* -------------------------------------------------------------------------- */

  var $brochures_form = $('#brochures-form');
  
  if ($brochures_form.length) {
    
    var brochures_form = $brochures_form[0];

    const on_error = (errors) => {
      $('#brochure-error').html('')
      errors.forEach(e => {
        $('#brochure-error').append(`${e}<br/>`)
      })
      $('#brochure-error').removeClass('opacity-0')
    }

    const on_success = () => {
      $(document).scrollTop(0)
      $brochures_form.remove()
      $('#brochure-form-success').removeClass('hidden')
    }
    
    var fields = {};

    brochures_form.querySelectorAll('[name]').forEach(function (field) {
      fields[field.name] = field;
    });

    // Displays all error messages and adds 'error' classes to the form fields with
    // failed validation.
    var handleError = function (response) {

      var errors = [];

      for (var key in response) {
        if (!response.hasOwnProperty(key)) continue;
        if (fields.hasOwnProperty(key)) fields[key].classList.add('error');
        Array.prototype.push.apply(errors, response[key]);
      }

      on_error(errors)

    }

    var onload = (e) => {
      if (e.target.status === 200) {
        on_success()
      } else {
        handleError(JSON.parse(e.target.response));
      }
    };

    var submit = function (e) {
      e.preventDefault();
      var request = new XMLHttpRequest();
      request.open('POST', e.target.action);
      request.onload = onload;
      request.send(new FormData(e.target));
      // Remove all 'error' classes of a possible previously failed validation.
      for (var key in fields) {
        if (!fields.hasOwnProperty(key)) continue;
        fields[key].classList.remove('error');
      }
      $('#brochure-error').addClass('opacity-0')

    };

    brochures_form.addEventListener('submit', submit);
  }

})