import $ from 'jquery';
import L from 'leaflet'
import MarkerCluster from 'leaflet.markercluster'
const HOST = window.location.href.split("/")[0] + '//' + window.location.href.split("/")[2]

export default class Map {

    constructor() {
        
        
        this.center = null
        
        if( location.hash ){
            let $center = $('.dpt-' + location.hash.replace('#','') )
            this.center = {
                lat : $center.data('lat'),
                lng : $center.data('lng')
            }
        }
        
        this.MAP_ICON = L.icon({
            iconUrl: HOST + '/assets/img/' + 'map-icon-cyan.svg',
            iconSize: 35
        })
        this.MAP_ICON_HOVER = L.icon({
            iconUrl: HOST + '/assets/img/' + 'map-icon-cyan.svg',
            iconSize: 40
        })

        this.MAP_ICON_REGION = L.icon({
            iconUrl: HOST + '/assets/img/' + 'map-icon-orange.svg',
            iconSize: 40
        })
        this.MAP_ICON_REGION_HOVER = L.icon({
            iconUrl: HOST + '/assets/img/' + 'map-icon-orange.svg',
            iconSize: 45
        })
        
        this.map = this.initMap()
        this.initmarkers(this.map)
        
        if( location.hash ){
            setTimeout(() => {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#map-dip").offset().top
                }, 300);
            }, 1200)
        }
    }

    initMap() {
        
        let center = this.center ? [ this.center.lat, this.center.lng ] : [ 46.46, 3.04 ]
        console.log(center)
        let map = L.map('map-dip', {
            zoomControl: false,
            gestureHandling: true,
            maxZoom: 16,
        }).setView( center, this.center ? 13 : 6)
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
        }).addTo(map)
        L.control.zoom({ position: 'bottomright' }).addTo(map);

        return map

    }

    initmarkers(map) {

        let features = []

        $('#map-dip-features div').each(function () {
            let feature = {
                position: { lat: $(this).data('lat'), lng: $(this).data('lng') },
                name: $(this).data('name'),
                type: $(this).data('type'),
                doctor: $(this).data('doctor'),
                structure: $(this).data('structure'),
                address: $(this).data('address'),
                email: $(this).data('email'),
                phone: $(this).data('phone'),
                doc: $(this).data('doc'),
                link: $(this).data('link'),
                // postId: $(this).data('post-id')
            }
            features.push(feature)
        })

        var markers = L.markerClusterGroup({
            spiderfyOnMaxZoom: false,
            showCoverageOnHover: false,
            disableClusteringAtZoom: 10,
            iconCreateFunction: function (cluster) {
                let size = Math.floor(cluster.getChildCount() / 100) + 30
                return L.divIcon({ html: `<div style="display:flex;align-items:center;justify-content:center;color:white;border-radius:${size / 2}px;background-color:#157A7E;width:${size}px;height:${size}px;transform:translateX(-${size / 2}px) translateY(-${size / 2}px)" class="cluster-icon">${cluster.getChildCount()}</div>` });
            }
        });

        features.forEach(feature => {
            
            let icon_key = feature.type == "Centre régional" ? "MAP_ICON_REGION" : "MAP_ICON"

            let marker = L.marker([feature.position.lat, feature.position.lng], { icon: this[icon_key] });
            // marker.addTo(map)

            marker.on('mouseover', () => {
                marker.setIcon(this[icon_key + "_HOVER"])
            })

            marker.on('mouseout', () => {
                marker.setIcon(this[icon_key])
            })

            // marker.on('click', () => {
            //     console.log('ouep')
            //     // this.getMapAnciensByVille(feature.ville)
            // });
            
            marker.bindPopup(`
                <div class="text-sm font-sans map-popup">
                    <div class="text-2xs mb-1 uppersmall text-spj-clay-6">${feature.type}</div>
                    <h3 class="font-bold mb-1">${feature.name}</h3>
                    <div class="mb-1">${feature.address}</div>
                    <div class="mb-1">Médecin référent: ${feature.doctor}</div>
                    ${feature.phone ? '<div>Tel: <a href="phone:' + feature.phone + '">' + feature.phone + '</a></div>' : ''}
                    ${feature.email ? '<div>Courriel: <a href="mailto:' + feature.email + '">' + feature.email + '</a></div>' : ''}
                    ${feature.link ? '<a class="block mt-2" target="_blank" href="' + feature.link + '">&rsaquo; Site internet</a>' : ''}
                    ${feature.doc ? '<a class="block mt-2" target="_blank" href="' + feature.doc + '">&rsaquo; PDF</a></div>' : ''}
                </div>
            `)

            markers.addLayer(marker)

        })

        map.addLayer(markers)

        // const iconPath = HOST + '/wp-content/themes/esmaartistique/assets/img/m/'
        // const iconHoverPath = iconPath + 'hover/'

        // var markerCluster = new MarkerClusterer(map, markers, {
        //    imagePath: iconPath + 'm',
        //    maxZoom: 7
        // });

    }

}