import $ from 'jquery'

export default class Filters {

    constructor(){
        
        this.url = $('#articles-wrapper').data('url')
        
        this.types = []
        this.categories = []

        this.item_selector = $('.js-filters').data('selector')
        this.init_filters()
        this.init_load_more()
        // alert('ouep')
    }

    init_filters(){

        let $checkboxes = $('.js-filters .custom-checkbox input')
        let $tags = $('.js-filters .tag-wrapper input')

        $checkboxes.on('click', () => {
            this.types = $checkboxes.toArray().filter( input => input.checked ).map( input => input.value )
            let url = this.build_url()
            this.load(url)
        })

        $tags.on('click', () => {
            this.categories = $tags.toArray().filter( input => input.checked ).map( input => input.value )
            let url = this.build_url()
            this.load(url)
        })

    }

    build_url(){
        console.log(this.categories)
        console.log(this.types)
        let url = this.url
        url += this.categories.length ? '/categories:' + this.categories.join(',') : ''
        url += this.types.length ? '/types:' + this.types.join(',') : ''
        console.log(url)
        return url
    }

    load(url){
        // return window.location.href = url
        const init = () => {
            this.init_load_more()
            this.init_filters()
        };
        
        $("#articles-wrapper").addClass('opacity-50')
          $.ajax({
            type: "POST",
            url,
            success: (data) => {
                
                // return $('html').html(data)
                // console.log($(data).find("#articles-wrapper").length)
                $("#articles-wrapper").removeClass('opacity-50')
                
                let articles = $(data).find("#articles-wrapper").html();
                console.log($(data).find("#articles-wrapper"))
                $("#articles-wrapper").html(articles);
                
                let filters = $(data).find(".js-filters").html();
                $(".js-filters").html(filters);
            
                let more = $(data).find("#show_more_wrapper").html();
                $("#show_more_wrapper").html(more);


                init();
            },
          });
    }

    init_load_more() {
        $("#show_more").on("click", () => {
          this.load_more($("#show_more").data("next"));
        });
      }
      
      load_more(url) {
        const init = this.init_load_more;
        $.ajax({
          type: "POST",
          url,
          success: (data) => {
            let articles = $(data).find("#articles-wrapper").html();
            
            let more = $(data).find("#show_more_wrapper").html();
            $("#articles-wrapper").append(articles);
            $("#show_more_wrapper").html(more);
            this.init_load_more()
            
          },
        });
      }
    // toggle (event) {
    //     let $toggler = $(event.currentTarget)
    //     let $icon = $toggler.find('.icon')
    //     let $filters = $('.js-filters')
    //     if ($filters.hasClass('hidden')){
    //         $filters.removeClass('hidden')
    //         $icon.addClass('rotate-180 transform')
    //     }else{
    //         $filters.addClass('hidden')
    //         $icon.removeClass('rotate-180 transform')
    //     }
    // }
    
    // none(){
    //     this._filter(this._apply_filters([]))
    // }

    // all(){
    //     this._filter(this._apply_filters())
    // }

    // _apply_filters ( arr ){
    //     this.$checkboxes.toArray().forEach( input => input.checked = ( arr === undefined || arr.includes(input.value)))
    //     return this.$checkboxes.toArray().filter( input => input.checked ).map( input => input.value )
    // }
    
    // _filter(categories){
    //     if(categories.length === 0){
    //         $(this.item_selector).removeClass('hidden')
    //     }else{
    //         $(this.item_selector).each( (i, el) => {
    //             let $item = $(el)
    //             if( $item.data('cat').split(' ').some( item => categories.includes(item)) ){
    //                 $item.removeClass('hidden')
    //             }else{
    //                 $item.addClass('hidden')
    //             }
    //         })
    //     }
    // }
}
